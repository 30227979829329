//Controller
import BaseController from "@/controllers/base-controller";

//Standard Components
import GlobalServices from "@/services/global";
import ApplicationService from "@/services/application";
import EarthApplicationService from "@/services/earth-application";
import EVENTS from "@/constants/events";
import MODULE from "@/constants/modules";
import APP from "@/constants/application";
import UtilityString from "@/utilities/strings";

import API_CONFIG from "@/config/API_CONFIG";
import Router from "@/router";
import AuthService from "@/auth/authService";
import {IController} from "@/interfaces/IController";
import {isDev} from "@/config/prototypes";
import cacheService from "@/services/cache";
import SearchService from "@/services/search";

//Interfaces
import {IUXSidePanel, ISidePanelHeader, IActionPrint, ILabelTitle, IButtonAction, ISidePanelInfoSection, IJupiterIFrame, ISidePanelTabs} from "@/interfaces/UXSidePanel";

import {IUXList, IListData, ITableHeader, ITable, ITableHoverMenu, ITableAction, IEditAction, IRoute, IManagePanelDropdown} from "@/interfaces/IUXList";
import {IInfo} from "@/interfaces/IUXInfo";

//Services
import ClientServices from "@/services/clients";
import {IFilterClient, IFilterItem} from "@/interfaces/IUXFilters";
import store from "@/store";
import IModuleFilter from "@/interfaces/filters/ModuleFilters";

class ClientsController implements IController {
  private config = new API_CONFIG();
  private auth = AuthService.getInstance();
  private utility = new UtilityString();
  private app = new ApplicationService();
  private global = new GlobalServices();

  private api = new ClientServices();
  private search = new SearchService();

  _clientType: EnumClientType;

  _tenantId = this.auth.getAuthObject().t;

  _actionId = MODULE.CLIENTS.ActionId;
  _moduleId = MODULE.CLIENTS.ModuleId;

  //Standard application event types, to standardize event logs and modal events
  EVENT_ACTION = EVENTS;

  //Stores the full data object state of the side panel to be returned.
  _sidepanel: IUXSidePanel = new Object() as IUXSidePanel;

  //Stores the default action, if the side panel action button is clicked without choosing an option from the list
  _defaultAction: IButtonAction = new Object() as IButtonAction;

  //Stores the default manage action, if the side panel action button is clicked without choosing an option from the list
  _defaultManageAction: IButtonAction = new Object() as IButtonAction;

  //The data object being passed in, eg.. Project, Client , Quote Data etc..
  _data = null;

  //Standard functionality such as print, email, close are stored in the base controller
  _base = new BaseController();

  //Reference to project earth for backward compability
  earth = new EarthApplicationService();

  // mode for handling the tabs to show or not
  _mode = "client";

  //Constructor Takes in a Module Data Object (Project, Client, Invoice)
  constructor() {}

  //Page Header / Title
  getTitle(): String {
    let title = this._data.name; //this._base.getTitle("Client", this._data.businessName, "");

    return title;
  }

  getSubTitle() {
    let subtitle = this._base.getSubTitle(this._data.category, this._data.location);

    return subtitle;
  }

  //Generate Drop Down Menu for Action Items
  getDropDownMenu(): IButtonAction[] {
    let actions: IButtonAction[] = [];

    //Old Work Order Screen
    let pageTitle = this.getTitle();
    let earthUrl = this.earth.getClientDetailsUrl(this._data.id);

    let oldClientAction = {
      id: 1,
      eventType: this.EVENT_ACTION.PROJECTS.ViewOld,
      menuTitle: "View Old Client Page",
      modalTitle: "Old Client Page",
      saveButtonTitle: "Old Client Page",
      data: this._data,
      componentName: "",
      url: earthUrl,
      UI_TYPE: APP.UI_TYPE.NewTab,
    };

    //Set Default Action
    this._defaultAction = oldClientAction;

    actions.push(oldClientAction);

    return actions;
  }

  //SECTION - Header
  getHeader(): ISidePanelHeader {
    let headerDataObj: ISidePanelHeader = new Object() as ISidePanelHeader;

    headerDataObj.titleSection = new Object() as ILabelTitle;

    //Hide/Show Print Preview Button
    headerDataObj.isPreviewEnabled = false;

    //Hide/Show Email Button
    headerDataObj.isEmailEnabled = false;

    //Hide/Show Action Button
    headerDataObj.isActionButtonEnabled = true;

    //Title Section (Left)
    headerDataObj.titleSection.headTitle = this.getTitle();

    //Sub Title
    headerDataObj.titleSection.subTitle = this.getSubTitle();

    //Action Button
    if (headerDataObj.isActionButtonEnabled) {
      headerDataObj.actions = this.getDropDownMenu();
      headerDataObj.defaultAction = this._defaultAction;
    }

    //Print Button
    if (headerDataObj.isPreviewEnabled) {
      headerDataObj.print = this._base.getPrintAction(this._data.id, MODULE.CLIENTS.ModuleId);
    }

    //Email Button
    if (headerDataObj.isEmailEnabled) {
      headerDataObj.email = this._base.getEmailAction(this._data, this._moduleId, this._actionId);
    }

    return headerDataObj;
  }

  //SECTION - Action Bar
  getActionBar(): IButtonAction[] {
    let actions: IButtonAction[] = [];

    //Edit Client
    let clientUrl = this.earth.getViewClientNavigationUrl(this._data.intClientID, this._data.businessName);

    actions.push({
      id: 1,
      eventType: this.EVENT_ACTION.CLIENTS.View,
      menuTitle: "Edit Client",
      modalTitle: "Editing Client",
      saveButtonTitle: "Save Changes",
      data: this._data,
      componentName: "",
      url: clientUrl,
      icon: "fa fa-pencil",
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Map
    let mapUrl = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(this._data.location);

    actions.push({
      id: 2,
      eventType: this.EVENT_ACTION.CLIENTS.ExternalView,
      menuTitle: "Map",
      modalTitle: "",
      saveButtonTitle: "",
      data: this._data,
      componentName: "",
      url: mapUrl,
      icon: "fa fa-map-marker",
      UI_TYPE: APP.UI_TYPE.NewTab,
    });

    //Dispatch
    actions.push({
      id: 3,
      eventType: this.EVENT_ACTION.DISPATCH.New,
      menuTitle: "Dispatch",
      modalTitle: "Dispatch to Technician",
      saveButtonTitle: "Send Message",
      data: this._data,
      componentName: "NewDispatch",
      url: "",
      icon: "fa fa-truck",
      UI_TYPE: APP.UI_TYPE.Modal,
    });

    //Sales Quote Navigation
    //If has sales quotes
    if (this._data.intSalesQuotationID > 0) {
      let quoteUrl = this.earth.getViewQuoteDetailsUrl(this._data.intSalesQuotationID);

      actions.push({
        id: 4,
        eventType: this.EVENT_ACTION.CLIENTS.View,
        menuTitle: "View Quote",
        modalTitle: "Editing Quote",
        saveButtonTitle: "Save Changes",
        data: this._data,
        componentName: "",
        url: quoteUrl,
        icon: "fa fa-file-text-o",
        UI_TYPE: APP.UI_TYPE.NewTab,
      });
    }

    return actions;
  }

  //SECTION - Info Summary - Read only
  getInfoSectionDetails(): ISidePanelInfoSection {
    let infosection: ISidePanelInfoSection = {
      //Left Side of info section
      leftSection: {
        title: "Due Date:" + " " + this._data.displayDueDate, //Red title H3 font size
        labels: [
          {
            id: 0,
            title: "Start Date: ",
            labelValue: this._data.displayStartDate,
          },
          {
            id: 1,
            title: "Est. Completion Date: ",
            labelValue: this._data.displayEndDate,
          },
        ],
      },

      //Right Side of info section
      rightSection: {
        title: "OVERDUE BY 1 DAY", //Red title H3 font size
        labels: [
          //{ id: 0, title: "Total: ", labelValue: this.$options.filters.currency(project.totalAmonunt) },
          {
            id: 1,
            title: "Created: ",
            labelValue: this._data.displayCreatedDate,
          },
          {id: 2, title: "Created By: ", labelValue: this._data.createdBy},
          // { id: 3, title: "Outstanding Balance: ", labelValue: Vue.$options.filters.currency(project.totalAmonunt) },
        ],
      },
    };

    return infosection;
  }

  //SECTION - Tabs
  getTabSection(): ISidePanelTabs[] {
    let tabs: ISidePanelTabs[] = [];

    //TAB - Location
    let locationTab = {
      id: 1,
      title: "Locations", //Name of the title
      actionButton: this.getActionButton(MODULE.LOCATION, "new", APP.UI_TYPE.Modal),
      editButton: this.getActionButton(MODULE.LOCATION, "edit", APP.UI_TYPE.Modal),
      componentName: "GenericTabList",
      componentPath: "@/components/_universal/tab-component-list",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.LOCATION.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the component
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Location DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxContacts = await this.global.getUXDropDown(MODULE.CONTACTS.ActionId, MODULE.LOCATION.ModuleId, null, {
          clientId: this._data.id,
          projectId: 0,
        });

        return {
          uxContacts,
        };
      },
    };

    tabs.push(locationTab);

    //TAB - Contacts
    let contactsTab = {
      id: 2,
      title: "Contacts", //Name of the title
      actionButton: this.getActionButton(MODULE.CONTACTS, "new", APP.UI_TYPE.Modal),
      editButton: this.getActionButton(MODULE.CONTACTS, "edit", APP.UI_TYPE.Modal),
      componentName: "GenericTabList",
      componentPath: "@/components/_universal/tab-component-list",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.CONTACTS.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the component
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Location DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxRoles = await this.global.getUXDropDown(MODULE.ROLES.ActionId, null, null, null);

        return {
          uxRoles,
        };
      },
    };

    tabs.push(contactsTab);
    //TAB - Equipments
    let equipmentsTab = {
      id: 3,
      title: "Equipment", //Name of the title
      actionButton: this.getActionButton(MODULE.EQUIPMENT, "new", APP.UI_TYPE.Modal),
      expandListButton: this.getActionButton(MODULE.EQUIPMENT, "expandlist", APP.UI_TYPE.Modal),
      editButton: this.getActionButton(MODULE.EQUIPMENT, "edit", APP.UI_TYPE.Modal),
      componentName: "GenericTabList",
      componentPath: "@/components/_universal/tab-component-list",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.EQUIPMENT.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the component
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Equipment Type DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxInventoryCategory = await this.global.getUXDropDown(MODULE.INVENTORY.UX.InventoryCategory, null, null, null);
        let moduleFilter: IModuleFilter = {
          clientId: this._data.id,
          projectId: 0,
        };
        let uxClientLocation = [];
        //Only call location api if we can filter by client id
        if (moduleFilter.clientId) {
          uxClientLocation = await this.global.getUXDropDown(MODULE.CLIENTS.UX.Location, null, null, moduleFilter);
        }
        return {
          uxInventoryCategory,
          uxClientLocation,
        };
      },
    };

    tabs.push(equipmentsTab);

    if ((this._mode === "prospects" || this._mode === "leads") && isDev()) {
      let callsTab = {
        id: 10,
        title: "Calls", //Name of the title
        actionButton: this.getActionButton(MODULE.COMBINATION.CALLS, "new", APP.UI_TYPE.Modal),
        editButton: this.getActionButton(MODULE.COMBINATION.CALLS, "edit", APP.UI_TYPE.Modal),
        componentName: "GenericTabList",
        componentPath: "@/components/_universal/tab-component-list",
        param: {
          //Dynamically passs any additional paramaters required for api calls etc.
          id: this._data.id,
          parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
          actionId: MODULE.COMBINATION.CALLS, //Action Id to determine what table list data to return
        },
        data: this._data, //Any Data need passing to the component
        UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      };

      tabs.push(callsTab);

      let emails = {
        id: 11,
        title: "Emails", //Name of the title
        actionButton: null,
        editButton: this.getActionButton(MODULE.COMBINATION.EMAILS, "edit", APP.UI_TYPE.Modal),
        componentName: "LeadProspectEmails",
        componentPath: "@/components/clients/leads-and-prospect-tabs/emails",
        param: {
          //Dynamically passs any additional paramaters required for api calls etc.
          id: this._data.id,
          parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
          actionId: MODULE.COMBINATION.EMAILS, //Action Id to determine what table list data to return
        },
        data: this._data, //Any Data need passing to the component
        UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      };

      tabs.push(emails);

      let tasksTab = {
        id: 12,
        title: "Tasks", //Name of the title
        actionButton: this.getActionButton(MODULE.TASKS, "new", APP.UI_TYPE.Modal),
        editButton: this.getActionButton(MODULE.TASKS, "edit", APP.UI_TYPE.Modal),
        componentName: "GenericTabList",
        componentPath: "@/components/_universal/tab-component-list",
        param: {
          //Dynamically passs any additional paramaters required for api calls etc.
          id: this._data.id,
          parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
          actionId: MODULE.TASKS.ActionId, //Action Id to determine what table list data to return
        },
        data: this._data, //Any Data need passing to the component
        UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
        //Call Back function to load Location DropDown List Results
        uxdata: async (pageName: string): Promise<Object> => {
          let uxInventory = await this.global.getUXDropDown(MODULE.INVENTORY.ActionId, null, null, null);

          return {
            uxInventory,
          };
        },
      };
      tabs.push(tasksTab);

      let meetingsTab = {
        id: 13,
        title: "Meetings", //Name of the title
        actionButton: this.getActionButton(MODULE.COMBINATION.MEETINGS, "new", APP.UI_TYPE.Modal),
        editButton: this.getActionButton(MODULE.COMBINATION.MEETINGS, "edit", APP.UI_TYPE.Modal),
        componentName: "GenericTabList",
        componentPath: "@/components/_universal/tab-component-list",
        param: {
          //Dynamically passs any additional paramaters required for api calls etc.
          id: this._data.id,
          parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
          actionId: MODULE.COMBINATION.MEETINGS, //Action Id to determine what table list data to return
        },
        data: this._data, //Any Data need passing to the component
        UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      };
      tabs.push(meetingsTab);
    }

    //TAB - Documents

    let documentsTab = {
      id: 4,
      title: "Documents/Photos", //Name of the Tab
      actionButton: this.getActionButton(MODULE.DOCUMENTS, "new", APP.UI_TYPE.Modal),
      componentName: "UniversalDocuments",
      componentPath: "@/components/_universal/tab-documents",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.DOCUMENTS.ActionId, //Action Id to determine what table list data to return
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(documentsTab);

    // //TAB - Billing Rates
    // let billingRatesTab = {
    //   id: 5,
    //   title: "Billing Rates", //Name of the title
    //   actionButton: this.getActionButton(MODULE.BILLINGRATES, "new"),
    //   editButton: this.getActionButton(MODULE.BILLINGRATES, "edit"),
    //   componentName: "GenericTabList",
    //   componentPath: "@/components/_universal/tab-component-list",
    //   param: {   //Dynamically passs any additional paramaters required for api calls etc.
    //     id: this._data.id,
    //     parentActionId: MODULE.CLIENTS.ActionId,  //Parent Table the actionId table must be filtered on, by Id
    //     actionId: MODULE.CONTACTS.ActionId, //Action Id to determine what table list data to return
    //   },
    //   data: this._data, //Any Data need passing to the component
    //   UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    //   //Call Back function to load Location DropDown List Results
    //   uxdata: async (pageName: string): Promise<Object> => {

    //     let uxContacts = await this.global.getUXDropDown(MODULE.BILLINGRATES.ActionId, MODULE.BILLINGRATES.ModuleId, null, {
    //       clientId: this._data.id,
    //       projectId: 0
    //     });

    //     return {
    //       uxContacts,
    //     }
    //   }
    // };

    // tabs.push(billingRatesTab);

    //TAB - Accounts Receivable
    let accountsReceivableTab = {
      id: 6,
      title: "Billing & Accounts Receivable", //Name of the title
      actionButton: null,
      editButton: null,
      componentName: "ClientTabAccountsReceivable",
      componentPath: "@/components/clients/client-tab-accounts-receivable.vue",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.CONTACTS.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the component
      details: this._sidepanel.details,
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Location DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxPaymentType = await this.global.getUXDropDown(MODULE.PAYMENT.UX.PaymentType, null, null, null);
        let uxPaymentTerms = await this.global.getUXDropDown(MODULE.PAYMENT.UX.PaymentTerms, null, null, null);
        let uxClientRateGroup = await this.global.getUXDropDown(MODULE.CLIENTS.UX.ClientRateGroup, null, null, null);
        let uxClientRating = await this.global.getUXDropDown(MODULE.CLIENTS.UX.ClientRating, null, null, null);
        // let uxClientRating = await this.global.getSimpleUXDropDown(this.clientRate.actionId, this.clientRate.parentActionId, this.clientRate.parent.id);
        let uxTaxRate = await this.global.getUXDropDown(MODULE.INVOICE.UX.InvoiceTaxRates);
        let uxClientList = await this.global.getUXDropDown(MODULE.CLIENTS.ActionId);
        return {
          uxPaymentType,
          uxPaymentTerms,
          uxClientRateGroup,
          uxClientRating,
          uxTaxRate,
          uxClientList,
        };
      },
    };

    tabs.push(accountsReceivableTab);

    // //TAB - Others
    let otherTab = {
      id: 7,
      title: "Other", //Name of the title
      actionButton: null,
      editButton: null,
      componentName: "ClientTabOther",
      componentPath: "@/components/clients/client-tab-other.vue",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.CONTACTS.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the component
      details: this._sidepanel.details,
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
      //Call Back function to load Location DropDown List Results
      uxdata: async (pageName: string): Promise<Object> => {
        let uxContacts = await this.global.getUXDropDown(MODULE.BILLINGRATES.ActionId, MODULE.BILLINGRATES.ModuleId, null, {
          clientId: this._data.id,
          projectId: 0,
        });

        return {
          uxContacts,
        };
      },
    };

    tabs.push(otherTab);

    //TAB - Notes
    let notesTab = {
      id: 8,
      title: "Notes", //Name of the tab
      actionButton: null,
      componentName: "NotesUniversal",
      componentPath: "@/components/_universal/notes-universal",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
        actionId: MODULE.NOTES.ActionId, //Action Id to determine what table list data to return
      },
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(notesTab);

    //TAB - History
    let historyTab = {
      id: 9,
      title: "History", //Name of the Tab
      actionButton: null,
      componentName: "Activity",
      componentPath: "@/components/activity/activity-history",
      param: {
        //Dynamically passs any additional paramaters required for api calls etc.
        id: this._data.id,
        parentActionId: 0, //Parent Table the actionId table must be filtered on, by Id
        actionId: 0, //Action Id to determine what table list data to return
        moduleId: MODULE.CLIENTS.ModuleId,
      }, //Action Id to determine what table list data to return
      data: this._data, //Any Data need passing to the modal box
      UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    };

    tabs.push(historyTab);

    //Functionality only to be used in SnapSuite Tenant
    if (this.auth.isSnapSuite()) {
      //TAB - Accounts Receivable
      let snapSuiteManagementTab = {
        id: 100,
        title: "Tenant Management", //Name of the title
        actionButton: null,
        editButton: null,
        componentName: "SnapSuiteTenantManagement",
        componentPath: "@/components/clients/snapsuite-only/snapsuite-tenant-management.vue",
        param: {
          //Dynamically passs any additional paramaters required for api calls etc.
          id: this._data.id,
          parentActionId: MODULE.CLIENTS.ActionId, //Parent Table the actionId table must be filtered on, by Id
          actionId: MODULE.CONTACTS.ActionId, //Action Id to determine what table list data to return
        },
        data: this._data, //Any Data need passing to the component
        details: this._sidepanel.details,
        UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
        //Call Back function to load Location DropDown List Results
        uxdata: async (pageName: string): Promise<Object> => {
          let uxPaymentType = await this.global.getUXDropDown(MODULE.PAYMENT.UX.PaymentType, null, null, null);
          let uxPaymentTerms = await this.global.getUXDropDown(MODULE.PAYMENT.UX.PaymentTerms, null, null, null);
          let uxClientRateGroup = await this.global.getUXDropDown(MODULE.CLIENTS.UX.ClientRateGroup, null, null, null);
          let uxClientRating = await this.global.getUXDropDown(MODULE.CLIENTS.UX.ClientRating, null, null, null);

          return {
            uxPaymentType,
            uxPaymentTerms,
            uxClientRateGroup,
            uxClientRating,
          };
        },
      };

      tabs.push(snapSuiteManagementTab);
    }

    //TAB - History
    // let historyTab = {
    //   id: 9,
    //   title: "History", //Name of the Tab
    //   actionButton: null,
    //   componentName: "Activity",
    //   componentPath: "@/components/activity/activity-history",
    //   param: {   //Dynamically passs any additional paramaters required for api calls etc.
    //     id: this._data.id,
    //     parentActionId: MODULE.CLIENTS.ActionId,  //Parent Table the actionId table must be filtered on, by Id
    //     actionId: MODULE.LOCATION.ActionId, //Action Id to determine what table list data to return
    //   }, //Action Id to determine what table list data to return
    //   data: this._data, //Any Data need passing to the modal box
    //   UI_TYPE: APP.UI_TYPE.Modal, //APP.UI_TYPE.Modal //The type of modal or new tab that should be opened.
    // };

    // tabs.push(historyTab);

    if (this._mode === "prospects" || this._mode === "leads") {
      // ids 3,6,7 are id from tabs e.g. id: 3 = equipments tab
      const removeIds = [3, 6, 7];
      removeIds.forEach((id) => {
        const index = tabs.findIndex((tab) => {
          return id === tab.id;
        });

        tabs.splice(index, 1);
      });
    }

    return tabs;
  }

  getActionButton(module, actionType, UI_MODAL_TYPE) {
    let title = "";
    let componentName = "";
    let eventType: any = this.EVENT_ACTION.LOCATION;

    //Set Location Tab Details
    if (module === MODULE.LOCATION) {
      eventType = this.EVENT_ACTION.LOCATION;

      if (actionType === "new") {
        title = "Add New Location";
        componentName = "NewLocation";
      } else if (actionType === "edit") {
        title = "Edit New Location";
        componentName = "NewLocation";
      }
    }

    //Set CALLS Tab Details
    if (module === MODULE.COMBINATION.CALLS) {
      eventType = this.EVENT_ACTION.COMBINATION.CALLS;

      if (actionType === "new") {
        title = "New Call";
        componentName = "NewCalls";
      } else if (actionType === "edit") {
        title = "Edit Call";
        componentName = "NewCalls";
      }
    }
    //Set MEETINGS Tab Details
    if (module === MODULE.COMBINATION.MEETINGS) {
      eventType = this.EVENT_ACTION.COMBINATION.MEETINGS;

      if (actionType === "new") {
        title = "New Meeting";
        componentName = "NewMeetings";
      } else if (actionType === "edit") {
        title = "Edit Meeting";
        componentName = "NewMeetings";
      }
    }
    if (module === MODULE.COMBINATION.EMAILS) {
      eventType = this.EVENT_ACTION.COMBINATION.EMAILS;

      if (actionType === "new") {
        title = "New Email";
        componentName = "NewEmailsTabModal";
      } else if (actionType === "edit") {
        title = "Edit Email";
        componentName = "NewEmailsTabModal";
      }
    }

    if (module === MODULE.TASKS) {
      eventType = this.EVENT_ACTION.TASK;

      if (actionType === "new") {
        title = "Assign Task";
        componentName = "NewProjectTask";
      } else if (actionType === "edit") {
        title = "Edit Task";
        componentName = "NewProjectTask";
      }
    }

    //Set Contacts Tab Details
    if (module === MODULE.CONTACTS) {
      eventType = this.EVENT_ACTION.CONTACTS;

      if (actionType === "new") {
        title = "Add New Contact";
        componentName = "NewContact";
      } else if (actionType === "edit") {
        title = "Edit New Contact";
        componentName = "NewContact";
      }
    }

    //Set Contacts Tab Details
    if (module === MODULE.EQUIPMENT) {
      eventType = this.EVENT_ACTION.EQUIPMENT;

      if (actionType === "new") {
        title = "Add New Equipment";
        componentName = "NewEquipment";
      } else if (actionType === "edit") {
        title = "Edit New Equipment";
        componentName = "NewEquipment";
      } else if (actionType === "expandlist") {
        title = "Equipment List";
        componentName = "ExpandEquipmentList";
      }
    }

    //Set Billing Rates Tab Details
    if (module === MODULE.BILLINGRATES) {
      eventType = this.EVENT_ACTION.BILLINGRATES;

      if (actionType === "new") {
        title = "Add New Billing Rate";
        componentName = "NewBillingRates";
      } else if (actionType === "edit") {
        title = "Edit Billing Rate";
        componentName = "NewBillingRates";
      }
    }

    //Set Billing Rates Tab Details
    if (module === MODULE.DOCUMENTS) {
      eventType = this.EVENT_ACTION.DOCUMENTS_PHOTOS;

      if (actionType === "new") {
        title = "Add Documents/Photos";
        componentName = this.EVENT_ACTION.DOCUMENTS_PHOTOS.New;
      } else if (actionType === "edit") {
        title = "Preview Document/Photo";
        componentName = this.EVENT_ACTION.IFRAME.PreviewImage;
      }
    }

    // //Set Billing Rates Tab Details
    // if (module === MODULE.ACCOUNTS_RECEIVABLE) {

    //   eventType = this.EVENT_ACTION.ACCOUNTS_RECEIVABLE;

    //   if (actionType === "new") {
    //     title = "Add Documents/Photos"
    //     componentName = "ClientTabAccountsReceivable"
    //   }
    //   else if (actionType === "edit") {
    //     title = "Preview Document/Photo"
    //     componentName = "ClientTabAccountsReceivable"

    //   }
    // }

    if (actionType === "new") {
      return {
        id: 1,
        actionId: module.ActionId,
        parentActionId: this._actionId,
        eventType: eventType.New,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: false,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null, //Will be set in the component
        moduleId: this._moduleId,
      };
    } else if (actionType === "edit") {
      return {
        id: 2,
        actionId: module.ActionId,
        eventType: eventType.View,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "Save",
        data: this._data,
        componentName: componentName,
        isInEditMode: true,
        url: "",
        icon: "fa fa-truck",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null, //Will be set in the component
      };
    } else if (actionType === "expandlist") {
      return {
        id: 3,
        actionId: module.ActionId,
        eventType: eventType.View,
        menuTitle: title,
        modalTitle: title,
        saveButtonTitle: "View List",
        data: this._data,
        componentName: componentName,
        isInEditMode: false,
        url: "",
        icon: "fa fa-expand",
        UI_TYPE: UI_MODAL_TYPE,
        dataObj: null, //Will be set in the component
      };
    }

    return null;
  }

  getListHeaders(): ITableHeader[] {
    let header: ITableHeader[] = [];

    header.push({
      id: 0,
      title: "Rating",
      columnName: "rating",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 1,
      title: "Client",
      columnName: "name",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 3,
      title: "Industry",
      columnName: "category",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 4,
      title: "Location",
      columnName: "location",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 6,
      title: "Phone",
      columnName: "phone",
      isSortable: false,
      isVisible: true,
    });

    header.push({
      id: 7,
      title: "Created By",
      columnName: "createdBy",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    header.push({
      id: 8,
      title: "Created",
      columnName: "createdDate",
      isSortable: false,
      isVisible: true,
      rightAlign: true,
    });

    return header;
  }

  getHoverMenu(): ITableHoverMenu {
    let hover = new Object() as ITableHoverMenu;

    //Not implemented for now
    hover.isEnabled = false;

    return hover;
  }

  getActions(): ITableAction {
    let actions = new Object() as ITableAction;

    //Not implemented for now
    actions.isEnabled = false;

    return actions;
  }

  //Call back function - to Load UX Dropdown List
  async loadDropDownList() {
    this._sidepanel.uxdata = async (pageName: string): Promise<Object> => {
      let uxIndustry = await this.global.getUXDropDown(MODULE.CLIENTS.UX.Industry);
      let uxLeadStatus = await this.global.getUXDropDown(MODULE.LEADS.UX.LeadStatus);
      let uxLeadStages = await this.global.getUXDropDown(MODULE.LEADS.UX.LeadStages);
      let uxClientType;
      if (pageName == APP.PAGE.Clients.Details) {
        uxClientType = await this.global.getUXDropDown(MODULE.CLIENTS.UX.ClientType, MODULE.CLIENTS.ModuleId);
      } else {
        uxClientType = await this.global.getUXDropDown(MODULE.CLIENTS.UX.ClientType, MODULE.SALES.ModuleId);
      }
      let uxRoles = await this.global.getUXDropDown(MODULE.ROLES.ActionId);
      let uxPaymentType = await this.global.getUXDropDown(MODULE.PAYMENT.UX.PaymentType, null, null, null);
      let uxPaymentTerms = await this.global.getUXDropDown(MODULE.PAYMENT.UX.PaymentTerms, null, null, null);
      let uxClientRateGroup = await this.global.getUXDropDown(MODULE.CLIENTS.UX.ClientRateGroup, null, null, null);
      let uxClientRating = await this.global.getUXDropDown(MODULE.CLIENTS.UX.ClientRating, null, null, null);
      let moduleFilter: IModuleFilter = {
        clientId: this._data.id,
        projectId: 0,
      };
      let uxClientLocation = [];
      //Only call location api if we can filter by client id
      if (moduleFilter.clientId) {
        uxClientLocation = await this.global.getUXDropDown(MODULE.CLIENTS.UX.Location, null, null, moduleFilter);
      }
      return {
        uxIndustry,
        uxClientType,
        uxRoles,
        uxPaymentType,
        uxPaymentTerms,
        uxClientRateGroup,
        uxClientRating,
        uxClientLocation,
        uxLeadStatus,
        uxLeadStages,
      };
    };
  }

 // Add these private members near the top of your ClientsController class:
// Use static properties to share state across all instances.
  private static _infoListCallCount: number = 0; // Tracks the number of times getInfoList has been called
  private static _cachedInfo: IInfo | null = null; // Caches the API response after a call
  private static _lastInfoListCallTimestamp: number = 0; // Stores the timestamp of the last API call


/**
 * getInfoList: Gets the info list from the API.
 * - Limits API calls to 3 per page load.
 * - Ensures the API is only called if 15 seconds have passed since the previous call.
 * - Subsequent calls within 15 seconds return a cached value.
 * - Also caches the response in localStorage for 15 minutes using CacheService.
 */
async getInfoList(filter): Promise<IInfo> {
  const now: number = Date.now();
  
  // Throttle: if less than 15 seconds have passed, return the cached info.
  if (now - ClientsController._lastInfoListCallTimestamp < 15000) {
    
    return ClientsController._cachedInfo 
      ? ClientsController._cachedInfo 
      : { isEnabled: false, data: null, isDetailsView: false } as IInfo;
  }
  
  // Limit: if call limit of 3 is reached, return the cached info.
  if (ClientsController._infoListCallCount >= 3) {
    
    return ClientsController._cachedInfo 
      ? ClientsController._cachedInfo 
      : { isEnabled: false, data: null, isDetailsView: false } as IInfo;
  }
  
  // Update state for a new API call.
  ClientsController._lastInfoListCallTimestamp = now;
  ClientsController._infoListCallCount++;

  // Adjust filter if client type is not "Commercial"
  if (this._clientType.toString() !== "Commercial") {
    filter.only = this._clientType.toString() === "Lead" ? "ClientType:Lead" : "ClientType:Prospect";
  }
  
  // Build a unique cache key from the filter.
  const cacheKey = "ClientsController_InfoList_" + JSON.stringify(filter);
  
  
  // Use CacheService to fetch data with a 15-minute expiry.
  let info: IInfo = await cacheService.fetchWithCache<IInfo>(cacheKey, async () => {
    let infoResult: IInfo = { isEnabled: false, data: null, isDetailsView: false } as IInfo;
    // Call the API to get results.
    let results = await this.search.getResults("global/list/info", filter);
    infoResult.data = results.resources;
    return infoResult;
  }, 15 * 60 * 1000); // 15 minutes

  ClientsController._cachedInfo = info;
  
  return info;
}


  //Info Summery
  async getInfoDetails(id): Promise<IInfo> {
    let info = new Object() as IInfo;

    //Not implemented for now
    info.isEnabled = false;

    let filter = {
      actionId: this._actionId,
      id: id,
    };

    let results = await this.search.getResults("global/details/info", filter);

    info.data = results.resources;

    info.isDetailsView = true;

    return info;
  }

  //Dynamically determin the filters for each list page...
  async getFilters(): Promise<IFilterClient> {
    let filter = new Object() as IFilterClient;
    let filters: IFilterItem[] = [];

    //If the advance filter button options should show
    filter.hasAdvancedFilter = false;

    //Not implemented for now
    filter.isEnabled = false;

    //Filter by client
    filters.push({
      id: 0,
      title: this._clientType.toString() == "Commercial" ? "Client" : this._clientType.toString(),
      name: "client",
      uxtype: "textbox",
      data: null,
      selectedValue: "",
      defaultValue: "",
      query: "businessName",
      isfilterByDisplayName: false,
    });

    // Only show on clients
    if (this._clientType.toString() == "Commercial") {
      //Filter by Industry
      filters.push({
        id: 1,
        title: "Industry",
        name: "industry",
        uxtype: "dropdown",
        data: await this.global.getUXDropDown(MODULE.INDUSTRY.ActionId, null, null, null),
        selectedValue: 1,
        defaultValue: 1,
        query: "c.intIndustryId",
      });
    }

    filters.push({
      id: 2,
      title: "City",
      name: "city",
      uxtype: "dropdown",
      data: await this.global.getUXDropDownByColumnName(MODULE.CLIENTS.ActionId, "city"),
      selectedValue: 1,
      defaultValue: 1,
      query: "city",
      isfilterByDisplayName: true,
    });

    if (this._clientType.toString() == "Commercial") {
      // Only show on clients
      filters.push({
        id: 3,
        title: "Rating",
        name: "rating",
        uxtype: "dropdown",
        data: await this.global.getUXDropDown(MODULE.CLIENTS.UX.ClientRating, null, null, null),
        selectedValue: 1,
        defaultValue: 1,
        query: "c.IntClientRatingID",
      });
    }

    if (this._clientType.toString() !== "Commercial") {
      // Only show on leads/prospect
      filters.push({
        id: 444,
        title: "Status",
        name: "status",
        uxtype: "dropdown",
        data: await this.global.getUXDropDown(MODULE.LEADS.UX.LeadStatus, null, null, null),
        selectedValue: 1,
        defaultValue: 1,
        query: "IntLeadStatusID",
      });

      filters.push({
        id: 555,
        title: "Stage",
        name: "stage",
        uxtype: "dropdown",
        data: await this.global.getUXDropDown(MODULE.LEADS.UX.LeadStages, null, null, null),
        selectedValue: 1,
        defaultValue: 1,
        query: "IntLifeCycleStageID",
      });
    }

    filters.push({
      id: 4,
      title: "Created By",
      name: "createdby",
      uxtype: "dropdown",
      data: await this.global.getUXDropDownByColumnName(MODULE.CLIENTS.ActionId, "createdBy"),
      selectedValue: 1,
      defaultValue: 1,
      query: "c.createdBy",
      isfilterByGuid: true,
    });

    filter.filters = filters;

    return filter;
  }

  async getListResults(filter) {
    filter.actionId = this._actionId;
    if (this._clientType.toString() !== "Commercial") {
      filter.only = this._clientType.toString() === "Lead" ? "ClientType:Lead" : "ClientType:Prospect";
    }
    // filter.intClientID = 57;
    return await this.search.getResults("global/list", filter);
  }

  //Generate Drop Down Menu for Action Items
  getManageDropDownMenu(): IButtonAction[] {
    let actions: IButtonAction[] = [];
    const manage = {
      id: 1,
      menuTitle: "Manage Board",
      modalTitle: "Manage Board",
      saveButtonTitle: "Save",
      data: this._data,
      componentName: this.EVENT_ACTION.LEADS.ManageBoard,
      eventType: this.EVENT_ACTION.LEADS.ManageBoard,
      UI_TYPE: APP.UI_TYPE.Modal,
    };

    //Set Default Action
    this._defaultManageAction = manage;

    actions.push(manage);

    return actions;
  }

  //SECTION - Header
  getManageHeader(): IManagePanelDropdown {
    let manageDataObj: IManagePanelDropdown = new Object() as IManagePanelDropdown;

    //Hide/Show Action Button
    manageDataObj.isManageDropdownEnabled = true;

    //manage Button
    if (manageDataObj.isManageDropdownEnabled) {
      manageDataObj.actions = this.getManageDropDownMenu();
      manageDataObj.defaultAction = this._defaultManageAction;
    }

    return manageDataObj;
  }

  //LIST - Entry for list page
  async list(filter, clientType): Promise<IUXList> {
    let list: IUXList = new Object() as IUXList;

    list.actionId = this._actionId;

    //Get Client Type
    this._clientType = clientType as EnumClientType;

    //settings
    list.settings = {
      isHoverOverRowEnabled: false,
      clientType,
    };

    //Table Header Properties
    list.table = new Object() as ITable;
    // list.table.header = this.getListHeaders();
    let param = {} as any;
    if (clientType !== "Commercial") {
      param.only = clientType === "Lead" ? "ClientType:Lead" : "ClientType:Prospect";
    }
    list.table.header = await this.global.getTableHeadersByActionIdAndAdditionalParam(this._actionId, param);

    //Data
    list.table.data = await this.getListResults(filter);


    //List Info
      // List Info: Limit getInfoList calls to 3 using _infoListCallCount.
  // If the call count is less than 3, perform the API call.
  // Otherwise, use the cached value or a default object.
  if (ClientsController._infoListCallCount < 3) {
    list.info = await this.getInfoList(filter);
  } else {
    list.info = ClientsController._cachedInfo 
      ? ClientsController._cachedInfo 
      : { isEnabled: false, data: null, isDetailsView: false } as IInfo;
  }

    //True - if we should load old ProjectEarth implementation via Iframe
    list.isSidePanelFrame = false;

    //Filters
    list.filter = await this.getFilters();

    //Hover Menu
    list.table.hover = new Object() as ITableHoverMenu;
    list.table.hover = this.getHoverMenu();

    //More Options - Action Menu
    list.table.actions = new Object() as ITableAction;
    list.table.actions = this.getActions();

    let editLink;

    //Set Edit Link
    //Commercial and Residential clients
    if (clientType) {
      if (clientType == "Commercial") {
        editLink = {
          name: "client-details",
          path: "/clients/details",
        };
      } else if (clientType == "Lead") {
        editLink = {
          name: "lead-details",
          path: "/leads/details",
        };
      } else if (clientType == "Prospect") {
        editLink = {
          name: "prospect-details",
          path: "/prospects/details",
        };
      }
    } else {
      editLink = {
        name: "client-details",
        path: "/clients/details",
      };
    }

    //Edit Action - i.e. when user clicks Edit, what should happen.
    list.editAction = new Object() as IEditAction;
    list.editAction.route = new Object() as IRoute;
    list.editAction.route = {
      name: editLink.name,
      path: editLink.path,
      param: {
        id: 0, //Id will be set once the record has been clicked
      },
    };

    list.manage = this.getManageHeader();

    return list;
  }

  initializedMode(mode) {
    if (mode) {
      this._mode = mode;
    } else {
      this._mode = "client";
    }
  }

  //MAIN - Entry for Details page
  async main(data, pageName = APP.PAGE.Clients.Details): Promise<IUXSidePanel> {
    this._data = data;
    this._data.typeId = MODULE.NOTES.CLIENT;
    this._sidepanel = store.getters.getSidePanel;
    this._sidepanel.actionId = this._actionId;

    //Specify the name of the page being loaded
    this._sidepanel.pageName = pageName;

    //Header
    this._sidepanel.headerDataObj = this.getHeader();

    //Decide if Side panel should show Iframe or load component view
    if (this._sidepanel.isSidePanelFrame) {
      this._sidepanel.url = this.earth.getClientDetailsUrl(this._data.id);
    } //IF not an IFrame then load standard data component
    else {
      //Load details data
      this._sidepanel.details = await this.global.getEditDetails(this._actionId, data.id);

      //Action Bar
      this._sidepanel.actionBarDataObj = this.getActionBar();

      //Info Stats
      this._sidepanel.info = await this.getInfoDetails(data.id);

      //Info Summary
      this._sidepanel.infoSectionDataObj = this.getInfoSectionDetails();

      //Tabs
      this._sidepanel.tabSectionDataObj = this.getTabSection();

      this._sidepanel.moduleId = this._moduleId;
      //UX Dropdown List - Call back function
      this.loadDropDownList();
    }

    return this._sidepanel;
  }
}

export default ClientsController;
